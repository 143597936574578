import { Alert, Proactivity } from 'pleinchamp-api-client';
import { City } from '@meteo/types';

export enum NotificationType {
  NotifProactivity = 'proactivity',
  NotifAlert = 'alert',
}

type NotificationFrontSpecific = { notificationType: NotificationType; locality: City };

export type ProactivityState = Proactivity &
  NotificationFrontSpecific & { notificationType: NotificationType.NotifProactivity };
export type AlertState = Alert & NotificationFrontSpecific & { notificationType: NotificationType.NotifAlert };

export type PlcNotification = ProactivityState | AlertState;
