import { ApiConfiguration, axiosInstance } from './api.conf';
import cookies from 'js-cookie';
import {
  Alert,
  AlertApi as AlertApiClient,
  LightAlert,
  LightProactivity,
  Proactivity,
  ProactivityApi as ProactivityApiClient,
  User,
} from 'pleinchamp-api-client';
import { LocationApi, TeamApi, UserApi } from '@api/business/api.utils';
import { SignUpProfile } from '@auth/business/auth.utils';
import { fromLocationToCity } from '@meteo/business/meteo.utils';
import { City } from '@meteo/types';
import { NotificationType, PlcNotification } from '@notifs/types';
import { Constants } from '@utils/Constants';
import { isBrowser } from '@utils/server';
import { AxiosResponse } from 'pleinchamp-api-client/node_modules/axios';

const AlertApi = new AlertApiClient(ApiConfiguration, undefined, axiosInstance);
export const ProactivityApi = new ProactivityApiClient(ApiConfiguration, undefined, axiosInstance);

export async function getUserLocations(isAuthenticated: boolean) {
  if (!isAuthenticated) {
    return [];
  }
  try {
    const { data } = await LocationApi.getLocations();
    // @ts-ignore
    return data.map(fromLocationToCity);
  } catch (err) {
    console.error('Error while calling user favorite locations', err);
    return Constants.EMPTY_ARRAY;
  }
}

function addLocalityToNotifications(
  notifications: Array<Proactivity | Alert>,
  favoriteLocalities: City[],
  notificationType: NotificationType
) {
  return notifications.reduce<PlcNotification[]>((previous, notification) => {
    const locality = favoriteLocalities.find(fl => fl.properties.citycode === notification.locationId.toString());
    if (locality) {
      previous.push({ ...notification, locality, notificationType } as PlcNotification);
    }
    return previous;
  }, []);
}

export async function getUserNotifications(localities: City[], isAuthenticated: boolean) {
  if (!isAuthenticated) {
    return [];
  }
  return Promise.all([
    ProactivityApi.getProactivities().then(r => r.data),
    AlertApi.getAlerts().then(r => r.data),
  ]).then(([proactivities, alerts]) => {
    return [
      ...addLocalityToNotifications(proactivities, localities, NotificationType.NotifProactivity),
      ...addLocalityToNotifications(alerts, localities, NotificationType.NotifAlert),
    ];
  });
}

export async function saveUserNotification(notif: PlcNotification) {
  const { notificationType, locality, id } = notif;
  const isUpdate = id && id > 0;
  const locationId = Number(locality.properties.citycode);

  switch (notificationType) {
    case NotificationType.NotifProactivity: {
      const { stateCode, type, comparisonCode, threshold, days } = notif as Proactivity;
      const lightProactivity: LightProactivity = {
        comparisonCode,
        days,
        locationId,
        stateCode,
        threshold,
        type,
      };
      if (isUpdate) {
        return ProactivityApi.updateProactivity(id, lightProactivity);
      }
      return ProactivityApi.insertProactivity(lightProactivity);
    }
    case NotificationType.NotifAlert: {
      const { stateCode, type, levelCode } = notif as Alert;
      const lightAlert: LightAlert = {
        levelCode,
        locationId,
        stateCode,
        type,
      };
      if (isUpdate && id) {
        return AlertApi.updateAlert(id, lightAlert);
      }
      return AlertApi.insertAlert(lightAlert);
    }
    default: {
      const swtichTest: never = notificationType;
      return swtichTest;
    }
  }
}

export async function deleteUserNotification(notif: PlcNotification): Promise<AxiosResponse<void> | null> {
  try {
    const { notificationType, id } = notif;
    switch (notificationType) {
      case NotificationType.NotifProactivity: {
        return await ProactivityApi.deleteProactivity(id);
      }
      case NotificationType.NotifAlert: {
        return await AlertApi.deleteAlert(id);
      }
      default: {
        const swtichTest: never = notificationType;
        return swtichTest;
      }
    }
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function getUserEmail() {
  try {
    const { data } = await UserApi.getAccount();
    return data.email;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function acceptTeamInvite(token: string) {
  try {
    await TeamApi.acceptInvitation({ token });
  } catch (err) {
    console.error('Error while accepting invitation', err);
    throw err;
  }
}

export async function acceptCgu(
  { address, birthday, company, email, firstname, genderCode, lastname, phoneNumber, professions, username }: User,
  profile: SignUpProfile
) {
  try {
    const { data } = await UserApi.updateAccount({
      address,
      birthday,
      company,
      email,
      firstname,
      genderCode,
      isFreeTOSAgreed: profile === SignUpProfile.member ? true : undefined,
      isProTOSAgreed: profile === SignUpProfile.pro ? true : undefined,
      lastname,
      phoneNumber,
      professions,
      username,
    });
    if (isBrowser() && window?.CookieConsent?.consented) {
      cookies.set('accountType', data.accountType, {
        expires: 365,
        sameSite: 'strict',
      });
      if (data.isSSO) {
        cookies.set('sso', 'atrium', {
          expires: 365,
          sameSite: 'strict',
        });
      }
    }
    return data;
  } catch (err) {
    console.error('Error while accepting TOS', err);
    throw err;
  }
}
