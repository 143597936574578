import { LinkProps } from 'next/link';
import React, { ComponentPropsWithRef, FC } from 'react';
import { BasicLink } from '@components/buttons/BasicLink/BasicLink.component';
import { TextProps } from '@components/Text/Text.types';
import classnames from 'classnames';

interface Props extends Omit<ComponentPropsWithRef<'a'>, 'href'> {
  active?: boolean;
  isDark?: boolean;
  linkProps: Pick<LinkProps, 'href' | 'as' | 'prefetch'>;
  variant?: TextProps['variant'];
  i18nKey: string;
}

const MenuLink: FC<Props> = ({
  isDark = false,
  variant = 'menu-link',
  active,
  linkProps,
  i18nKey,
  className,
  ...rest
}) => {
  const rootClassName = classnames(className, { active });
  const { as, href, prefetch } = linkProps;
  return (
    <BasicLink
      as={as}
      className={rootClassName}
      href={href}
      prefetch={prefetch}
      role="menuitem"
      textProps={{ flavour: isDark ? 'light' : 'dark', i18nKey, variant }}
      {...rest}
    />
  );
};

export { MenuLink };
